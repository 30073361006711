import React from 'react';
import { about } from '../data';

const About = () => {
  return (
    <section id="about" data-section>
        <div className="container">
            <div className="about__section">
                <h1 className="about__title">About</h1>
                <div className="about__content">
                    <div className="about__intro">
                        <p>
                            <span>Hi there! </span> <br />
                            {about.content}
                        </p>
                        <a href="mailto:gautham22poy@gmail.com">Reach me</a>
                    </div>
                    <img src={about.image} alt="myPic" id="image" />
                </div>
                <div className="skills">
                    {about.skills.map((skill,index) => (
                        <div className="skills__section" key={index}>
                            <div className="skills__title">
                                {skill.title}
                            </div>
                            <div className="skills__content">
                                {skill.names.map((name,index) => (
                                    <div className="skill__name" key={index}>{name}</div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    </section>
  )
};

export default About;