import React from 'react';
import styled from 'styled-components';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { works } from '../data';

const Works = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 900,
        repsponsive: [
            {
                breakpoint: 700,
                settings: 'unslick'
            }
        ],
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true
    };
    return (
        <section id="works" data-section>
            <Carousel {...settings}>
                <div className="container">
                    <h1 className="works__title">Projects</h1>
                    <div className="cards">
                        {works.section1.map((i) => (
                            <div className="card" key={i}>
                                <div className="box">
                                    <div className="content">
                                        <h1>{works.projects[i].number}</h1>
                                        <h2>{works.projects[i].title}</h2>
                                        <p>{works.projects[i].description}</p>
                                        <a href={works.projects[i].url} rel="noreferrer" target="_blank">
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                            View Site
                                        </a>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div> 
                </div>
                <div className="container">
                    <h1 className="works__title">Projects</h1>
                    <div className="cards">
                        {works.section2.map((i) => (
                            <div className="card" key={i}>
                                <div className="box">
                                    <div className="content">
                                        <h1>{works.projects[i].number}</h1>
                                        <h2>{works.projects[i].title}</h2>
                                        <p>{works.projects[i].description}</p>
                                        <a href={works.projects[i].url} rel="noreferrer" target="_blank">
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                            View Site
                                        </a>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div> 
                </div>
            </Carousel>
            <MobileWrap>
                <div className="container">
                    <h1 className="works__title">Projects</h1>
                    <div className="cards">
                        {works.projects.map((project, i) => (
                            <div className="card" key={i}>
                                <div className="box">
                                    <div className="content">
                                        <h1>{project.number}</h1>
                                        <h2>{project.title}</h2>
                                        <p>{project.description}</p>
                                        <a href={project.url} rel="noreferrer" target="_blank">
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                            View Site
                                        </a>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div> 
                </div>
            </MobileWrap>
        </section>
    )
};

export default Works;

const Carousel = styled(Slider)`

    .slick-list {
        overflow: visible;
    }
    ul li button {
        &:before {
            font-size: 10px;
            color: rgb(150, 158, 171);
        }
    }
    li.slick-active button::before {
        color: white;
    }
    button {
        z-index: 1;
    }
    .slick-prev {
        left: -20px;
    }
    .slick-next {
        right: -5px;
    }
    .slick-arrow::before {
        font-size: 30px;
        color: white;
    }

    @media (max-width: 970px) {
        .slick-prev, .slick-next{
            top: 23%;
        }
    }

    @media (max-width: 700px) {
        display: none;
    }
`;

const MobileWrap = styled.div`
    display: block;
    @media (min-width: 700px) {
        display: none;
    }
`;
