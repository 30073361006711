import React, { useState } from 'react';
import { Header, Home, About, Works, Contact, Footer } from "./components";
import Loader from './components/Loader/Loader';
import Backdrop from '@mui/material/Backdrop';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
    const [isLoading, setIsLoading] = useState(false);
    return (
        <>
            <Header />
            <Home />
            <About />
            <Works />
            <Contact setIsLoading={setIsLoading} />
            <Footer />
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
                <Loader />
            </Backdrop>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
        </>
    )
};

export default App;