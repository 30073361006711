import React from 'react';

import styles from './Loader.module.css';

function Loader() {
    return (
        <div className={styles.cubeContainer}>
            <div className={styles.cube}>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    );
}

export default Loader;