import React from 'react';

const Footer = () => {
  return (
    <footer>
        <div className="container">
            <div className="social">
                <a href="https://github.com/Gautham-22" rel="noreferrer" target="_blank"><i className="fab fa-github"></i></a>
                <a href="https://instagram.com/gautham._.kumar" rel="noreferrer" target="_blank"><i className="fab fa-instagram"></i></a>
                <a href="https://twitter.com/Gautham2212" rel="noreferrer" target="_blank"><i className="fab fa-twitter"></i></a>
                <a href="https://www.linkedin.com/in/gauthamkumarganesan/" rel="noreferrer" target="_blank"><i className="fab fa-linkedin"></i></a>
            </div>
        </div>
    </footer>
  )
};

export default Footer;