export const about = {
    content: "I am an ardent Developer and Passionate coder. I like to code things from scratch and enjoy bringing ideas to life with the help of new tools & technologies. Looking to collaborate with any tech minds out there!",
    image: "/images/me.jpeg",
    skills: [
        {
            title: "Stack",
            names: ["MongoDB", "Express", "React", "NodeJs"]
        },
        {
            title: "Other",
            names: ["HTML , CSS , JS", "SQL", "PHP"]
        },
        {
            title: "Languages",
            names: ["C", "C++", "Python"]
        },
    ]
}

export const works = {
    projects: [
        {
            number: "01",
            title: "Placement Portal",
            description: "It provides seamless application process for students, easy access for companies to fetch student details/documents for shortlisting & admin capabilities for managing everything.",
            url: "https://cuic.annauniv.edu"
        },
        {
            number: "02",
            title: "Multimodal Speech ER",
            description: "It explores speech emotion recognition by using BERT for text; ResNet34, and AlexNet for audio, and explored multimodal combinations for enhanced accuracy.",
            url: "https://github.com/Gautham-22/multimodal-er"
        },
        {
            number: "03",
            title: "Speech to Blog",
            description: "It seamlessly converts spoken lectures into well-formatted blog posts by Finetuning LED models, using techniques like speech recognition and extractive summarization.",
            url: "https://github.com/Gautham-22/speech-to-blog"
        },
        {
            number: "04",
            title: "CSAU 2022",
            description: "A website revamp for Computer Society of Anna University using ReactJS & Tailwind CSS. CSAU is one of CEG's (College of Engineering, Guindy) oldest technical clubs",
            url: "https://csau.in/"
        },
        {
            number: "05",
            title: "DOROTICS 2023",
            description: "First National Symposium on Emerging Technologies in DOROTICS (Drones & Robotics) will be held in College of Engineering Guindy, Anna University, from March 30th to April 1st 2023.",
            url: "https://dorotics-2023.vercel.app/"
        },
        {
            number: "06",
            title: "PIXELS 2023",
            description: "A website for Pixels; the official photography and videography club of CEG and is a rapturous platform for those who are passionate about the art of photography.",
            url: "https://pixelsceg.com"
        },
        {
            number: "07",
            title: "CFMS",
            description: "A Crowd Funding Management System that enables users to raise funds for a cause and donate for that cause; with admin verification for each post of the users.",
            url: "https://github.com/Gautham-22/cfms"
        },
        {
            number: "08",
            title: "Google Search",
            description: "A google search clone website with the ability to fetch real-time links, images, news and videos; made with React & Tailwind CSS.",
            url: "https://google-search-app.netlify.app/"
        },
    ],
    section1: [0, 1, 2, 3],
    section2: [4, 5, 6, 7],
};