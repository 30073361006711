import React from 'react';

const Header = () => {
  return (
    <header>
        <div className="container">
            <div className="row">
                <h2 id="logo">Portfolio</h2>
                <div id="hamburger">
                    <i className="fas fa-bars"></i>
                </div>
                <nav id="nav" className="hidden">
                    <li><a href="#home" className="nav__links active">Home</a></li>
                    <li><a href="#about" className="nav__links">About</a></li>
                    <li><a href="#works" className="nav__links">Works</a></li>
                    <li><a href="#contact" className="nav__links">Contact</a></li>
                    <li><a href="https://gk-blog.notion.site/gk-blog/Personal-Blog-14f9aab1cd3d41fe99a65d38bdf402f5" className="nav__links" rel="noreferrer" target="_blank">Blog</a></li>
                </nav>
            </div>
        </div>
    </header>
  )
};

export default Header;