import React, { useState } from 'react';
import { toast } from 'react-toastify';


const Contact = ({ setIsLoading }) => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [msg, setMsg] = useState("");


    const handleSubmit = async (e) => {
        setIsLoading(true);
        e.preventDefault();

        let details = { name, email, 'message': msg };
        let formBody = [];
        for (let property in details) {
            formBody.push(encodeURIComponent(property) + "=" + encodeURIComponent(details[property]));
        }
        formBody = formBody.join("&");

        fetch('https://portfolio-mail.vercel.app/message', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            },
            body: formBody
        }).then(res => res.json())
            .then(res => {
                setIsLoading(false);
                toast.success('Message sent', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
            })
            .catch(err => {
                setIsLoading(false);
                toast.error('Submission Failed. Try again!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
            });
    }

    return (
        <section id="contact" data-section>
            <div className="container">
                <h1 className="contact__title">Contact</h1>
                <form action="/message" method="POST">
                    <div className="contact-left">
                        <h1 className="c-l-heading">Details</h1>
                        <div className="f-name">
                            <p>Name</p>
                            <input type="text" name="name" placeholder="Full Name" autoComplete="off" required spellCheck="false" value={name} onChange={(e) => setName(e.target.value)} />
                        </div>
                        <div className="f-email">
                            <p>Email</p>
                            <input type="email" name="email" placeholder="Example@hmail.com" autoComplete="off" required spellCheck="false" value={email} onChange={(e) => setEmail(e.target.value)} />
                        </div>
                    </div>
                    <div className="contact-right">
                        <div className="message">
                            <p>Message</p>
                            <textarea name="message" cols="20" rows="5" placeholder="Write message..."
                                autoComplete="off" required spellCheck="false" value={msg} onChange={(e) => setMsg(e.target.value)}></textarea>
                        </div>
                        <button type="submit" onClick={handleSubmit}>Submit</button>
                    </div>
                </form>
            </div>
        </section>
    );
};

export default Contact;