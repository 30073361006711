import React from 'react';

const Home = () => {
  return (
    <section id="home" data-section>
        <div id="home__text">
            <h1 data-text="Hello...">Hello...</h1>
            <div id="intro">
                <h1>I'm Gautham kumar</h1>
            </div>
        </div>
    </section>
  )
};

export default Home;